import React from 'react';
import './infoButton.css'

const InfoButton = () => {
    return (
        <>
        <a target='_blank' href='https://www.horarioseinscripcionesbelfort.es/'>
        <div className='infoButton'>
            <p>Horarios, precios e inscripciones</p>
            
        </div>       
        </a> 
        </>

    );
}

export default InfoButton;
