import React from 'react';

const Privacidad = () => {
    return (
        <div className='blog-sub-category'>
            <div className="category-section">
                <h4>POLÍTICA DE PRIVACIDAD DE DATOS</h4>
                <p>En cumplimiento de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal (LOPD), le informamos que los datos personales que nos suministre utilizando nuestro correo electrónico de contacto y formulario web, serán tratados de forma confidencial y pasarán a formar parte de ficheros automatizados titularidad de Belfort Language que han sido debidamente inscritos en la Agencia Española de Protección de Datos (www.agpd.es).</p>
                <p>En concordancia con la normativa actualmente vigente en materia de protección de datos (LOPD), así como en materia de servicios de la sociedad de la información y de comercio electrónico (LSSICE), el Usuario acepta que los datos aportados sean incorporados a ficheros titularidad de Belfort Language, con la finalidad de facilitar la prestación de los servicios solicitados y el previsible envío de información sobre ofertas, nuevos servicios u otros motivos por los que la entidad deba ponerse en contacto con el Usuario/cliente.</p>
                <p>Belfort Language se compromete a NO REMITIR COMUNICACIONES COMERCIALES SIN IDENTIFICARLAS COMO TALES, conforme a lo dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la Información y de comercio electrónico. A estos efectos será considerado como comunicación comercial toda información que se envíe A LOS CLIENTES Y/O USUARIOS de www.belfortlanguage.com siempre que tenga por finalidad el mantenimiento de la relación existente entre el cliente y/o usuario y LA EMPRESA, así como el desempeño de las tareas de información, y otras actividades propias de los servicios que presta.</p>
                <p>En todo caso, Belfort Language conservará de forma confidencial y cumpliendo la obligación de secreto de todos los datos personales recabados y contenidos en sus ficheros según lo dispuesto en la normativa vigente en materia de protección de datos de carácter personal.</p>
                <p>Consentimiento para finalidades publicitarias: Ud. consiente y autoriza expresamente a Belfort Language para que pueda tratar sus datos personales, con la finalidad de incluir sus datos en campañas publicitarias promovidas por www.belfortlanguage.com y, en definitiva, consiente el envío de comunicaciones comerciales a través de cualquier medio ya sea electrónico (teléfono, fax, correo electrónico, SMS, MMS, etc.) o no electrónico (correo ordinario, etc.), sin que sirvan los medios enumerados como una lista cerrada, siempre que tenga por finalidad el mantenimiento de la relación existente entre el cliente y/o usuario y LA EMPRESA, así como el desempeño de las tareas de información, y otras actividades propias de los servicios que presta.</p>
                <p>Puede revocar el consentimiento para finalidades publicitarias según procedimiento descrito en Ejercicio Derechos ARCO.</p>
                <p>Asimismo, le informamos que los datos de carácter personal que nos proporcione al enviarnos el currículum vitae a través del correo electrónico belfortformacio@gmail.com , así como aquéllos que se pudieran generar en la participación dentro de algún proceso de selección de personal, se incluirán en ficheros titularidad de Belfort Language. La finalidad de los mismos es valorar la posibilidad de una contratación.</p>
                <p>En caso de que usted quisiera sustituir o actualizar sus datos nos lo debe hacer constar enviando su currículum de nuevo. Una vez recibamos su petición los anteriores datos serán destruidos según la LOPD.</p>
                <p>Al aceptar la política de protección de datos, Ud. manifiesta que ha sido informado en los términos del Art. 5.1 de la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal y que otorga su consentimiento para el tratamiento de sus datos personales a las finalidades anteriormente descritas, sin perjuicio del ejercicio de los correspondientes derechos ARCO o la revocación del consentimiento prestado.</p>
                <p>Belfort Language se reserva el derecho de modificar su política de privacidad de acuerdo a su criterio, o a causa de un cambio legislativo, jurisprudencial o en la práctica empresarial.
                Si Belfort Language incluyese alguna modificación, el nuevo texto será publicado en este mismo sitio Web, donde el Usuario podrá tener conocimiento de la política de privacidad actual de www.belfortlanguage.com</p>
                <p>El interesado podrá ejercitar los derechos de acceso, rectificación, cancelación y oposición mediante carta certificada, o forma equivalente que permita acreditar su recepción, dirigida a Belfort Language, , 08042 Barcelona, Barcelona. La solicitud deberá contener nombre, apellidos del interesado, copia del DNI y, en los casos que se admita, de la persona que lo represente, así como documento acreditativo de la representación, petición en que se concreta la solicitud, domicilio a efectos de notificaciones, fecha y firma del solicitante y documentos acreditativos de la petición que formula. Si la solicitud no reúne los requisitos especificados se requerirá su subsanación. Respecto al derecho de acceso, únicamente se denegará cuando la solicitud sea formulada por persona distinta del afectado. No se exigirá contraprestación alguna por el ejercicio de los derechos.</p>

            </div>
            
        </div>
    );
}

export default Privacidad;
