import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';



// recursos
import Logo from '../../multimedia/logo.svg'
import LogoMobile from '../../multimedia/logo-min.svg'


  
const Navbar = () => {

      return(
        <nav className="navbar">
            <Link className='navbar-brand' to={'/'}><img src={Logo} alt="logo belfort language centre" className='logo-web'/><img src={LogoMobile} alt="logo belfort language centre" className='logo-mobile' /></Link>
        <ul id="ul" className="nav">
          <Link to={'/'}><li>INICIO</li></Link>
          <Link to={'/idiomas'}><li>IDIOMAS</li></Link>
          <Link to={'/cambridge'}><li>CAMBRIDGE</li></Link>
          <Link to={'/blog'}><li>BLOG</li></Link>
        </ul>
      </nav>
      )

}

export default Navbar;
