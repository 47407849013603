import React from 'react';
import Wsp from '../../multimedia/WhatsApp-logo.svg'
const Whatsappbutton = () => {
    return (
        <div className='contenedor-wsp-button'>
            <a href="https://wa.me/+34628561259" target="_blank"  id='whatsapp'><div className="button-wsp"><img src={Wsp} alt=""  id='whatsapp'/></div></a>
            
        </div>
    );
}

export default Whatsappbutton;
